import clsx from 'clsx';
import React, { FunctionComponent, useState } from 'react';
import '../assets/style/index.css';
import AngelListIcon from '../components/AngelListIcon';
import Logo from '../components/Logo';
import SEO from '../components/SEO';

const IndexPage: FunctionComponent = () => {
  const [darkMode, setDarkMode] = useState(true);

  return (
    <div
      className={clsx(
        'min-h-screen px-8 flex flex-col items-center justify-center',
        darkMode && 'bg-black',
      )}
    >
      <SEO />
      <div className="flex-1 w-full flex justify-end mt-8">
        <a
          aria-label="AngelList"
          className="text-gray fill-current w-10 md:w-12"
          href="https://angel.co/company/hodlvc"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AngelListIcon />
        </a>
      </div>
      <main className="w-full mx-auto max-w-5xl">
        <button
          className={clsx(
            'w-full focus:outline-none text-black fill-current',
            darkMode && 'text-white',
          )}
          onClick={(): void => setDarkMode(!darkMode)}
          type="button"
        >
          <Logo />
        </button>
      </main>
      <footer className="flex-1 flex items-end mb-8 font-sans text-gray md:text-lg text-center">
        © {new Date().getFullYear()}
      </footer>
    </div>
  );
};

export default IndexPage;
