import React, { FunctionComponent } from 'react';

const Logo: FunctionComponent = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 461.497 119.13">
    <g>
      <path
        d="M135.665 119.13a59.565 59.565 0 1159.565-59.565 59.639 59.639 0 01-59.565 59.565zm0-84.369a24.8 24.8 0 1024.8 24.8 24.9 24.9 0 00-24.8-24.8z"
        transform="translate(132.929) translate(-76.1)"
      />
    </g>
    <g>
      <path
        d="M167.958 119.13H158.7V84.369h9.258a24.8 24.8 0 000-49.608H158.7V0h9.258a59.565 59.565 0 010 119.13z"
        transform="translate(277.213) translate(-158.7)"
      />
    </g>
    <g>
      <path
        d="M301.016 119.13H214.9V0h34.761v84.369h51.355z"
        transform="translate(375.381) translate(-214.9)"
      />
    </g>
    <g>
      <path d="M0 0H34.761V119.13H0z" />
    </g>
    <g>
      <path d="M0 0H34.761V119.13H0z" transform="translate(69.521)" />
    </g>
  </svg>
);

export default Logo;
